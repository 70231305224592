import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)` 
  width: 158px; 
  height: 56px;
  margin-top: 2px;
  margin-right: 1rem;
`;

const GooglePlay = ({ className }) => ( 
  <Svg viewBox="-321 591 148 44" className={className}>   
    <g>
      <g>
        <path d="M-177.7,634.5h-138.5c-2.6,0-4.7-2.1-4.7-4.7v-33.6c0-2.6,2.1-4.7,4.7-4.7h138.5c2.6,0,4.7,2.1,4.7,4.7l0,33.6
          C-173,632.4-175.1,634.5-177.7,634.5z M-316.3,592.5c-2.1,0-3.7,1.7-3.7,3.7v33.6c0,2,1.7,3.7,3.7,3.7h138.5
          c2.1,0,3.7-1.7,3.7-3.7l0-33.6c0-2.1-1.7-3.7-3.7-3.7H-316.3z"/>
      </g>
      <path d="M-224.4,622.5c-0.7,0.3-1.4,0.6-2.3,0.6c-1.9,0-3.6-1.5-4.1-4l1.5-0.1l4.7-1.9c-0.1-0.5-0.4-1.3-1.4-2
        c-0.6-0.4-1.3-0.6-2.1-0.6c-2.1,0-4.6,1.5-4.6,4.6c0,1.5,0.6,3.2,2.1,4.2c1,0.7,2,0.8,2.7,0.8c0.6,0,1.3-0.1,2-0.4
        c0.2-0.1,0.4-0.2,0.7-0.4L-224.4,622.5C-224.2,622.4-224.4,622.5-224.4,622.5z M-230.9,618c0-2.2,1.5-2.8,2.3-2.8
        c1.1,0,2,0.9,2,1.7c0,0.3-0.2,0.4-0.6,0.5l-3.7,1.6C-230.9,618.7-230.9,618.3-230.9,618z"/>
      <path d="M-234.2,622.4l0-11.3c0.1-0.7,0.3-0.9,0.9-1.3h-2.9l-1.5,0.7h1.6l0,11.9v0.4c0,0.4-0.1,0.5-0.5,1.1h3.5
        l0.7-0.4c-0.3,0-0.7-0.1-1-0.1C-234.1,623.3-234.2,623.1-234.2,622.4z"/>
      <path d="M-237.2,614.8h-4.1c-1.1,0-2.5,0.2-3.6,1.1c-0.9,0.7-1.3,1.8-1.3,2.7c0,1.6,1.2,3.1,3.3,3.1c0.2,0,0.4,0,0.6,0
        c-0.1,0.2-0.2,0.4-0.2,0.8c0,0.6,0.3,1,0.6,1.4c-0.9,0.1-2.6,0.2-3.8,0.9c-1.2,0.7-1.6,1.7-1.6,2.4c0,1.5,1.4,2.9,4.3,2.9
        c3.5,0,5.3-1.9,5.3-3.8c0-1.4-0.8-2.1-1.7-2.8l-0.7-0.6c-0.2-0.2-0.5-0.4-0.5-0.9c0-0.4,0.3-0.7,0.6-1c0.8-0.7,1.7-1.4,1.7-2.8
        c0-1.5-1-2.3-1.4-2.7h1.2L-237.2,614.8z M-239,627c0,1.2-1,2.2-3,2.2c-2.2,0-3.5-1-3.5-2.4c0-1.4,1.3-1.9,1.7-2.1
        c0.8-0.3,1.9-0.3,2.1-0.3c0.2,0,0.3,0,0.5,0C-239.6,625.5-239,626-239,627z M-240.6,620.6c-0.3,0.3-0.9,0.6-1.4,0.6
        c-1.7,0-2.5-2.2-2.5-3.5c0-0.5,0.1-1.1,0.4-1.5c0.3-0.4,0.9-0.7,1.4-0.7c1.7,0,2.5,2.2,2.5,3.7
        C-240.1,619.5-240.1,620.2-240.6,620.6z"/>
      <path d="M-251.9,614.6c-3.1,0-5.2,2.2-5.2,4.8c0,2.2,1.7,4.7,4.9,4.7c3.3,0,5.1-2.5,5.1-4.8
        C-247.1,616.9-248.9,614.6-251.9,614.6z M-249.7,622.5c-0.5,0.6-1.3,1-2.1,1c-2.4,0-3.4-3.2-3.4-5.2c0-0.9,0.1-1.7,0.9-2.4
        c0.5-0.4,1.2-0.7,1.8-0.7c2.5,0,3.3,3.3,3.3,5.1C-249.1,621.1-249.2,621.9-249.7,622.5z"/>
      <path d="M-262.6,614.6c-3.1,0-5.2,2.2-5.2,4.8c0,2.2,1.7,4.7,4.9,4.7c3.3,0,5.1-2.5,5.1-4.8
        C-257.8,616.9-259.6,614.6-262.6,614.6z M-260.4,622.5c-0.5,0.6-1.3,1-2.1,1c-2.4,0-3.4-3.2-3.4-5.2c0-0.9,0.1-1.7,0.9-2.4
        c0.5-0.4,1.1-0.7,1.8-0.7c2.5,0,3.3,3.3,3.3,5.1C-259.8,621.1-259.9,621.9-260.4,622.5z"/>
      <path d="M-273.9,620.3l2.8-0.2v3c-0.6,0.2-1.2,0.5-2.4,0.5c-4.1,0-6.7-3.3-6.7-6.8c0-3.3,2.2-5.5,5.2-5.5
        c2.2,0,3.4,1.1,4,1.8l-0.5,0.7l0.7-0.1l1.6-1.5c-0.4-0.3-1.1-0.8-2.5-1.2c-0.9-0.2-1.9-0.4-2.9-0.4c-4.8,0-7.6,3.4-7.6,7
        c0,3,2,6.8,7.2,6.8c1,0,2-0.2,3.1-0.3l2.7-0.6h0v-2.3c0-0.2,0-0.7,0-0.9c0-0.1,0.1-0.2,0.2-0.3l0.5-0.5h-4L-273.9,620.3z"/>
      <path d="M-215.3,614.5c-1.4,0-2.6,0.8-3.3,1.7v-1.5h-1v12.9h1v-5.1c0.8,1.1,2,1.7,3.3,1.7c2.5,0,4.2-1.9,4.2-4.9
        C-211,616.4-212.7,614.5-215.3,614.5z M-215.4,623.4c-1.3,0-2.6-1.1-3.1-2v-4.1c0.5-0.9,1.8-1.9,3.1-1.9c2.1,0,3.3,1.7,3.3,4
        C-212.1,621.7-213.3,623.4-215.4,623.4z"/>
      <rect x="-209.6" y="611.2" width="1" height="12.9"/>
      <path d="M-193.6,622.9l-3.4-8.2h-1.1l4,9.4l-0.7,1.7c-0.4,0.9-0.8,1.1-1.4,1.1c-0.2,0-0.6-0.1-0.8-0.2l-0.2,0.9
        c0.3,0.1,0.7,0.1,1,0.1c1.1,0,1.9-0.6,2.4-1.7l4.9-11.4h-1.2L-193.6,622.9z"/>
      <path d="M-199.3,622.2v-4.6c0-1-0.3-1.7-1-2.3c-0.7-0.5-1.6-0.8-2.7-0.8c-1.1,0-2,0.3-2.7,0.8c-0.7,0.5-1,1.2-1,1.9
        l0,0.1h1c0-0.5,0.2-1,0.7-1.3c0.5-0.3,1.1-0.5,1.9-0.5c0.8,0,1.5,0.2,1.9,0.6c0.5,0.4,0.7,0.9,0.7,1.6v1.2h-2.4
        c-1.3,0-2.3,0.3-3.1,0.8c-0.8,0.5-1.2,1.2-1.2,2c0,0.8,0.3,1.5,0.8,2c0.5,0.5,1.3,0.7,2.3,0.7c0.8,0,1.4-0.2,2.1-0.5
        c0.6-0.3,1.1-0.8,1.5-1.3c0,0.2,0,0.5,0,0.7c0,0.2,0.1,0.5,0.2,0.8v0h1.2c-0.1-0.3-0.2-0.6-0.2-0.9
        C-199.2,622.9-199.3,622.5-199.3,622.2z M-200.4,621.3c-0.2,0.6-0.7,1.1-1.3,1.5c-0.6,0.4-1.3,0.6-2.1,0.6c-0.7,0-1.2-0.2-1.6-0.5
        c-0.4-0.3-0.6-0.8-0.6-1.3c0-0.6,0.3-1.1,0.9-1.5c0.6-0.4,1.3-0.6,2.3-0.6h2.4V621.3z"/>
      <path d="M-279.2,601.3h1.9v1c-0.3,0.3-0.9,0.6-1.5,0.6c-1.3,0-2.2-1-2.2-2.3c0-1.3,0.9-2.3,2.2-2.3
        c0.7,0,1.3,0.4,1.7,0.8l0.9-0.5c-0.5-0.7-1.3-1.3-2.6-1.3c-1.9,0-3.3,1.3-3.3,3.3c0,2,1.5,3.3,3.3,3.3c1.1,0,2-0.5,2.6-1.2v-2.4h-3
        V601.3z"/>
      <polygon points="-274.8,603.7 -270.4,603.7 -270.4,602.8 -273.7,602.8 -273.7,601 -270.5,601 -270.5,600 -273.7,600 
        -273.7,598.4 -270.4,598.4 -270.4,597.4 -274.8,597.4"/>
      <polygon points="-269.2,598.4 -267.3,598.4 -267.3,603.7 -266.2,603.7 -266.2,598.4 -264.3,598.4 -264.3,597.4 
        -269.2,597.4 	"/>
      <rect x="-260.4" y="597.4" width="1.1" height="6.3"/>
      <polygon points="-258.1,598.4 -256.2,598.4 -256.2,603.7 -255.1,603.7 -255.1,598.4 -253.1,598.4 -253.1,597.4 
        -258.1,597.4 	"/>
      <path d="M-246.3,597.3c-1.9,0-3.3,1.4-3.3,3.3c0,1.9,1.3,3.3,3.3,3.3c1.9,0,3.3-1.4,3.3-3.3
        C-243,598.7-244.4,597.3-246.3,597.3z M-246.3,602.9c-1.3,0-2.1-1-2.1-2.3c0-1.3,0.8-2.3,2.1-2.3c1.3,0,2.1,1,2.1,2.3
        C-244.2,601.9-245,602.9-246.3,602.9z"/>
      <polygon points="-237.3,601.8 -240.6,597.4 -241.7,597.4 -241.7,603.7 -240.6,603.7 -240.6,599.2 -237.3,603.7 
        -236.2,603.7 -236.2,597.4 -237.3,597.4"/>
      <g>
        <polygon points="-294,621.9 -296.7,618.8 -305,628.1"/>
        <polygon points="-304.8,608.4 -296.7,617.6 -293.9,614.6"/>
        <path d="M-288.9,617.4l-4.3-2.4l-3,3.3l2.8,3.2l4.4-2.5C-288.1,618.6-288.1,617.8-288.9,617.4z"/>
        <path d="M-306.9,608.9v18.5c0,0.6,0.2,0.9,0.6,1l9.1-10.2l-9.1-10.3C-306.7,608.1-306.9,608.4-306.9,608.9z"/>
      </g>
    </g>
  </Svg>
);

export default GooglePlay;
