import React from 'react';
import {
  Go,
  Svg
} from './Styling';

const OverviewIcon = ({ className }) => ( 
  <Svg viewBox="0 0 100 100" className={className}>   
    <path fill="currentColor" d="M15,33.5h15c1.9,0,3.5-1.6,3.5-3.5V15c0-1.9-1.6-3.5-3.5-3.5H15c-1.9,0-3.5,1.6-3.5,3.5v15C11.5,31.9,13.1,33.5,15,33.5z
	  M13.5,15c0-0.8,0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5v15c0,0.8-0.7,1.5-1.5,1.5H15c-0.8,0-1.5-0.7-1.5-1.5V15z"/>
    <path fill="currentColor" d="M42.5,33.5h15c1.9,0,3.5-1.6,3.5-3.5V15c0-1.9-1.6-3.5-3.5-3.5h-15c-1.9,0-3.5,1.6-3.5,3.5v15C39,31.9,40.6,33.5,42.5,33.5z
      M41,15c0-0.8,0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5v15c0,0.8-0.7,1.5-1.5,1.5h-15c-0.8,0-1.5-0.7-1.5-1.5V15z"/>
    <path fill="currentColor" d="M85,11.5H70c-1.9,0-3.5,1.6-3.5,3.5v15c0,1.9,1.6,3.5,3.5,3.5h15c1.9,0,3.5-1.6,3.5-3.5V15C88.5,13.1,86.9,11.5,85,11.5z
      M86.5,30c0,0.8-0.7,1.5-1.5,1.5H70c-0.8,0-1.5-0.7-1.5-1.5V15c0-0.8,0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5V30z"/>
    <path fill="currentColor" d="M15,61h15c1.9,0,3.5-1.6,3.5-3.5v-15c0-1.9-1.6-3.5-3.5-3.5H15c-1.9,0-3.5,1.6-3.5,3.5v15C11.5,59.4,13.1,61,15,61z
      M13.5,42.5c0-0.8,0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5v15c0,0.8-0.7,1.5-1.5,1.5H15c-0.8,0-1.5-0.7-1.5-1.5V42.5z"/>
    <path fill="currentColor" d="M42.5,61h15c1.9,0,3.5-1.6,3.5-3.5v-15c0-1.9-1.6-3.5-3.5-3.5h-15c-1.9,0-3.5,1.6-3.5,3.5v15C39,59.4,40.6,61,42.5,61z
      M41,42.5c0-0.8,0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5v15c0,0.8-0.7,1.5-1.5,1.5h-15c-0.8,0-1.5-0.7-1.5-1.5V42.5z"/>
    <path fill="currentColor" d="M85,39H70c-1.9,0-3.5,1.6-3.5,3.5v15c0,1.9,1.6,3.5,3.5,3.5h15c1.9,0,3.5-1.6,3.5-3.5v-15C88.5,40.6,86.9,39,85,39z
      M86.5,57.5c0,0.8-0.7,1.5-1.5,1.5H70c-0.8,0-1.5-0.7-1.5-1.5v-15c0-0.8,0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5V57.5z"/>
    <path fill="currentColor" d="M15,88.5h15c1.9,0,3.5-1.6,3.5-3.5V70c0-1.9-1.6-3.5-3.5-3.5H15c-1.9,0-3.5,1.6-3.5,3.5v15C11.5,86.9,13.1,88.5,15,88.5z
      M13.5,70c0-0.8,0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5v15c0,0.8-0.7,1.5-1.5,1.5H15c-0.8,0-1.5-0.7-1.5-1.5V70z"/>
    <path fill="currentColor" d="M42.5,88.5h15c1.9,0,3.5-1.6,3.5-3.5V70c0-1.9-1.6-3.5-3.5-3.5h-15c-1.9,0-3.5,1.6-3.5,3.5v15C39,86.9,40.6,88.5,42.5,88.5z
      M41,70c0-0.8,0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5v15c0,0.8-0.7,1.5-1.5,1.5h-15c-0.8,0-1.5-0.7-1.5-1.5V70z"/>
    <path fill="currentColor" d="M85,66.5H70c-1.9,0-3.5,1.6-3.5,3.5v15c0,1.9,1.6,3.5,3.5,3.5h15c1.9,0,3.5-1.6,3.5-3.5V70C88.5,68.1,86.9,66.5,85,66.5z
      M86.5,85c0,0.8-0.7,1.5-1.5,1.5H70c-0.8,0-1.5-0.7-1.5-1.5V70c0-0.8,0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5V85z"/>
  </Svg>
);

function OverviewLink() {
  return (
    <Go exact to="/my">
      <OverviewIcon/> Overview
    </Go>
  )
}

export default OverviewLink;
