import React from 'react';
import {
  Go,
  Svg
} from './Styling';

const SettingsIcon = ({ className }) => ( 
  <Svg viewBox="0 0 100 100" className={className}>   
    <path fill="currentColor" d="M74,64.5c0.7-1,1.9-1.6,3.1-1.3l7.4,1.3c2.7,0.5,5.3-1.3,5.8-4c0,0,0,0,0-0.1l1.1-6.2c0.5-2.7-1.3-5.3-4-5.8c0,0,0,0-0.1,0
    L79.9,47c-1.2-0.2-2.2-1.2-2.4-2.4c-0.2-1.1,0.1-2.3,1-3l6.8-5.8c2-1.7,2.4-4.6,0.8-6.7L82,23.3c-1.5-2.1-4.3-2.8-6.6-1.5l-7.8,4.3
    c-1,0.5-2.2,0.5-3.2-0.1c-1-0.7-1.6-1.9-1.3-3.1l1.3-7.4c0.5-2.7-1.3-5.3-4-5.8c0,0,0,0-0.1,0l-6.2-1.1c-2.7-0.5-5.3,1.3-5.8,4
    c0,0,0,0,0,0.1L47,20.1c-0.2,1.2-1.2,2.2-2.4,2.4c-1.1,0.2-2.3-0.1-3-1l-5.8-6.8c-1.7-2-4.6-2.4-6.7-0.9L23.3,18
    c-2.1,1.5-2.8,4.3-1.5,6.6l4.3,7.8c0.5,1,0.5,2.2-0.1,3.2c-0.7,1-1.9,1.6-3.1,1.3l-7.4-1.3c-2.7-0.5-5.3,1.3-5.8,4c0,0,0,0,0,0.1
    l-1.1,6.2c-0.5,2.7,1.3,5.3,4,5.8c0,0,0,0,0.1,0l7.4,1.3c1.2,0.2,2.2,1.2,2.4,2.4c0.2,1.1-0.1,2.3-1,3l-6.8,5.8
    c-2,1.7-2.4,4.6-0.9,6.7l4.1,5.8c1.5,2.1,4.3,2.8,6.6,1.5l7.8-4.3c1-0.5,2.2-0.5,3.2,0.1c1,0.7,1.6,1.9,1.3,3.1l-1.3,7.4
    c-0.5,2.7,1.3,5.3,4,5.8c0,0,0,0,0.1,0l6.2,1.1c2.7,0.5,5.3-1.3,5.8-4c0,0,0,0,0-0.1l1.3-7.4c0.2-1.2,1.2-2.2,2.4-2.4
    c1.1-0.2,2.3,0.1,3,1l5.8,6.8c1.7,2,4.6,2.4,6.7,0.8l5.8-4.1c2.1-1.5,2.8-4.3,1.5-6.6l-4.3-7.8C73.3,66.7,73.4,65.5,74,64.5L73.1,64
    l-0.9-0.5c-1,1.6-1,3.5-0.1,5.1l4.3,7.8c0.8,1.4,0.4,3.1-0.9,4l-5.8,4.1c-1.3,0.9-3,0.7-4-0.5l-5.8-6.8c-1.2-1.4-3.1-2.1-4.9-1.7
    c-2,0.4-3.5,2-3.9,4L49.7,87c-0.3,1.7-1.9,2.8-3.5,2.5L40,88.4c-1.6-0.3-2.7-1.8-2.5-3.4c0,0,0-0.1,0-0.1l1.3-7.4
    c0.4-2-0.6-4.1-2.3-5.2c-0.8-0.5-1.8-0.8-2.7-0.8c-0.9,0-1.7,0.2-2.4,0.6l-7.8,4.3c-1.4,0.8-3.1,0.4-4-0.9l-4.1-5.8
    c-0.9-1.3-0.7-3,0.5-4l6.8-5.8c1.4-1.2,2-3.1,1.6-4.9c-0.4-2-2-3.5-4-3.9L13,49.7c-1.6-0.3-2.7-1.8-2.5-3.4c0,0,0-0.1,0-0.1l1.1-6.2
    c0.3-1.6,1.8-2.7,3.4-2.5c0,0,0.1,0,0.1,0l7.4,1.3c2,0.4,4.1-0.6,5.2-2.3c1-1.6,1-3.5,0.1-5.1l-4.4-7.8c-0.8-1.4-0.4-3.1,0.9-4
    l5.8-4.1c1.3-0.9,3-0.7,4,0.5l5.8,6.8c1.2,1.4,3.1,2,4.9,1.7c2-0.4,3.5-2,3.9-4l1.4-7.5c0.3-1.6,1.8-2.7,3.4-2.5c0,0,0.1,0,0.1,0
    l6.2,1.1c1.6,0.3,2.7,1.8,2.5,3.4c0,0,0,0.1,0,0.1l-1.3,7.4c-0.4,2,0.6,4.1,2.3,5.2c1.6,1,3.5,1,5.2,0.1l7.8-4.3
    c1.4-0.8,3.1-0.4,4,0.9l4.1,5.8c0.9,1.3,0.7,3-0.5,4l-6.8,5.8c-1.4,1.2-2,3.1-1.6,4.9c0.4,2,2,3.5,4,3.9l7.5,1.4
    c1.6,0.3,2.7,1.8,2.5,3.4c0,0,0,0.1,0,0.1L88.4,60c-0.3,1.6-1.8,2.7-3.4,2.5c0,0-0.1,0-0.1,0l-7.4-1.3c-2-0.4-4.1,0.5-5.1,2.3
    l0.8,0.5L74,64.5L74,64.5z"/>
    <path fill="currentColor" d="M50,34c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S58.8,34,50,34z M50,64c-7.7,0-14-6.3-14-14s6.3-14,14-14
      s14,6.3,14,14S57.7,64,50,64z"/>
  </Svg>
);

function SettingsLink() {
  return (
    <Go to="/my/settings">
      <SettingsIcon/> Settings
    </Go>
  )
}

export default SettingsLink;
