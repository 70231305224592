import React from 'react';
import {
  Go,
  Svg
} from './Styling';

const YourSIMIcon = ({ className }) => ( 
  <Svg viewBox="0 0 100 100" className={className}>   
    <g transform="translate(0,-952.36218)">
      <path fill="currentColor" d="M33.8,959.4c-0.2,0-0.4,0.1-0.5,0.3l-19.1,19c-0.2,0.2-0.3,0.5-0.3,0.7v20c0,0.5,0.5,1,1,1s1-0.5,1-1v-19.6l18.4-18.4H78
        c2.3,0,3.7,0.8,4.7,1.9s1.3,2.7,1.3,4.1v70c0,1.4-0.4,2.9-1.3,4c-0.9,1.1-2.3,2-4.7,2H22c-2.3,0-3.7-0.8-4.7-2
        c-0.9-1.1-1.3-2.7-1.3-4v-32c0-0.5-0.5-1-1-1s-1,0.5-1,1v32c0,1.8,0.5,3.7,1.8,5.3c1.3,1.6,3.4,2.7,6.2,2.7h56
        c2.8,0,4.9-1.1,6.2-2.7c1.3-1.6,1.8-3.5,1.8-5.3v-70c0-1.8-0.5-3.7-1.8-5.3s-3.4-2.7-6.2-2.7C63.3,959.4,48.5,959.4,33.8,959.4z
        M32,979.4c-5.1,0-9,4.1-9,9v40c0,4.9,3.9,9,9,9h28c5.1,0,9-4.1,9-9v-40c0-4.9-3.9-9-9-9H32z M32,981.4h3v14.1
        c-0.3,0-0.7-0.1-1-0.1h-9v-7C25,984.4,27.9,981.4,32,981.4z M37,981.4h18v14.4c-1,0.3-1.9,0.7-2.6,1.2c-1.5,1-2.4,2.6-2.4,4.4v14
        c0,1.8,0.9,3.4,2.4,4.4c0.7,0.5,1.6,1,2.6,1.2v14.3H37V1021c1-0.3,1.9-0.7,2.6-1.2c1.5-1,2.4-2.6,2.4-4.4v-14
        c0-1.8-0.9-3.4-2.4-4.4c-0.7-0.5-1.6-0.9-2.6-1.2V981.4z M57,981.4h3c4.1,0,7,3.1,7,7v7h-9c-0.3,0-0.7,0-1,0.1V981.4z M25,997.4h9
        c1.9,0,3.5,0.5,4.5,1.2s1.5,1.6,1.5,2.8v6H25V997.4z M58,997.4h9v10H52v-6c0-1.2,0.5-2.1,1.5-2.8S56.1,997.4,58,997.4z M25,1009.4
        h15v6c0,1.2-0.5,2.1-1.5,2.8c-1,0.7-2.6,1.2-4.5,1.2h-9V1009.4z M52,1009.4h15v10h-9c-1.9,0-3.5-0.5-4.5-1.2
        c-1-0.7-1.5-1.6-1.5-2.8V1009.4z M35,1021.3v14h-3c-4.1,0-7-3.1-7-7v-7h9C34.3,1021.4,34.7,1021.4,35,1021.3z M57,1021.3
        c0.3,0,0.7,0,1,0h9v7c0,3.9-2.9,7-7,7h-3V1021.3z"/>
    </g>
  </Svg>
);

function YourSIMLink() {
  return (
    <Go to="/my/sim">
      <YourSIMIcon/> Your SIM
    </Go>
  )
}

export default YourSIMLink;
