import React from 'react';
import styled from 'styled-components';
import { device } from '../../rules/device';
import Icon from '../Icon';

const Container = styled.div`
  width: 1170px;
  height: auto;
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media ${device.bp1} {
    width: 100%;
    padding: 0 1rem;
    justify-content: space-evenly;
  }

  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

const Box = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Svg = styled(Icon)` 
  width: 100px; 
  height: 100px;
`;

const SecurePayment = ({ className }) => ( 
  <Svg viewBox="0 0 100 100" className={className}>   
    <g>
      <path d="M86.1,85.5H73.5c-1.8,0-3.2-1.4-3.2-3.2V71c0-0.4,0.3-0.7,0.7-0.7h17.7c0.4,0,0.7,0.3,0.7,0.7v11.3
        C89.3,84.1,87.9,85.5,86.1,85.5z M71.6,71.5v10.7c0,1.1,0.9,1.9,1.9,1.9h12.6c1.1,0,1.9-0.9,1.9-1.9V71.5H71.6z"/>
      <path d="M79.8,77.9c-1,0-1.9-0.9-1.9-1.9s0.9-1.9,1.9-1.9c1,0,1.9,0.9,1.9,1.9S80.8,77.9,79.8,77.9z M79.8,75.4
        c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6C80.4,75.6,80.1,75.4,79.8,75.4z"/>
      <path d="M79.8,81.7c-0.3,0-0.6-0.3-0.6-0.6v-3.8c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v3.8C80.4,81.4,80.1,81.7,79.8,81.7z"
        />
      <path d="M86.1,71.5h-2.5c-0.4,0-0.7-0.3-0.7-0.7v-3.8c0-0.9-0.3-1.7-1-2.3c-0.6-0.6-1.5-0.9-2.3-0.9c-1.7,0.1-3,1.6-3,3.4v3.5
        c0,0.4-0.3,0.7-0.7,0.7h-2.5c-0.4,0-0.7-0.3-0.7-0.7v-3.5c0-3.5,2.3-6.5,5.6-7.1h0c2.1-0.4,4.2,0.1,5.8,1.4
        c1.6,1.3,2.5,3.3,2.5,5.4v3.8C86.8,71.2,86.4,71.5,86.1,71.5z M84.2,70.3h1.3v-3.2c0-1.7-0.8-3.3-2.1-4.4c-1.3-1.1-3.1-1.5-4.8-1.2
        h0c-2.6,0.5-4.5,3-4.5,5.9v2.9h1.3v-2.9c0-2.5,1.9-4.6,4.2-4.7c1.2-0.1,2.4,0.4,3.3,1.2c0.9,0.8,1.4,2,1.4,3.2V70.3z"/>
      <path d="M79.8,71.5c-0.3,0-0.6-0.3-0.6-0.6v-7.6c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v7.6C80.4,71.3,80.1,71.5,79.8,71.5z"
        />
      <path d="M70.9,76.6H15.2c-2.5,0-4.5-2-4.5-4.5V39.3c0-2.5,2-4.5,4.5-4.5H76c2.5,0,4.5,2,4.5,4.5v21.5c0,0.3-0.3,0.6-0.6,0.6
        c-0.3,0-0.6-0.3-0.6-0.6V39.3c0-1.8-1.4-3.2-3.2-3.2H15.2c-1.8,0-3.2,1.4-3.2,3.2v32.9c0,1.8,1.4,3.2,3.2,3.2h55.7
        c0.3,0,0.6,0.3,0.6,0.6C71.6,76.3,71.3,76.6,70.9,76.6z"/>
      <path d="M79.8,56.6c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.4-0.4-0.8l5.6-19.9c0.5-1.7-0.5-3.5-2.2-4L25.9,15.9c-1.7-0.5-3.5,0.5-4,2.2
        L17,35.6c-0.1,0.3-0.4,0.5-0.8,0.4c-0.3-0.1-0.5-0.4-0.4-0.8l4.9-17.5c0.7-2.4,3.1-3.8,5.5-3.1L83,30.7c2.4,0.7,3.8,3.2,3.1,5.5
        l-5.6,19.9C80.3,56.4,80.1,56.6,79.8,56.6z"/>
      <path d="M79.8,48.7h-8.9c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h8.2v-5.1H12v5.1h41.2c0.3,0,0.6,0.3,0.6,0.6s-0.3,0.6-0.6,0.6
        H11.3c-0.3,0-0.6-0.3-0.6-0.6v-6.3c0-0.3,0.3-0.6,0.6-0.6h68.5c0.3,0,0.6,0.3,0.6,0.6v6.3C80.4,48.4,80.1,48.7,79.8,48.7z"/>
      <path d="M34.2,63.9H20.2c-1.7,0-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2h13.9c1.7,0,3.2,1.4,3.2,3.2S35.9,63.9,34.2,63.9z M20.2,58.9
        c-1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9h13.9c1,0,1.9-0.9,1.9-1.9s-0.9-1.9-1.9-1.9H20.2z"/>
      <path d="M17.7,70.3c-0.3,0-0.6-0.3-0.6-0.6v-2.5c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v2.5C18.3,70,18,70.3,17.7,70.3z"/>
      <path d="M29.1,70.3c-0.3,0-0.6-0.3-0.6-0.6v-2.5c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v2.5C29.7,70,29.4,70.3,29.1,70.3z"/>
      <path d="M25.3,70.3c-0.3,0-0.6-0.3-0.6-0.6v-2.5c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v2.5C25.9,70,25.6,70.3,25.3,70.3z"/>
      <path d="M36.7,70.3c-0.3,0-0.6-0.3-0.6-0.6v-2.5c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v2.5C37.3,70,37,70.3,36.7,70.3z"/>
      <path d="M21.5,70.3c-0.3,0-0.6-0.3-0.6-0.6v-2.5c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v2.5C22.1,70,21.8,70.3,21.5,70.3z"/>
      <path d="M32.9,70.3c-0.3,0-0.6-0.3-0.6-0.6v-2.5c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v2.5C33.5,70,33.2,70.3,32.9,70.3z"/>
      <path d="M65.8,70.3H45.6c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h20.3c0.3,0,0.6,0.3,0.6,0.6C66.5,70,66.2,70.3,65.8,70.3z"
        />
      <path d="M64.6,48.7H57c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h7.6c0.3,0,0.6,0.3,0.6,0.6S64.9,48.7,64.6,48.7z"/>
    </g>
  </Svg>
);

const FastDelivery = ({ className }) => ( 
  <Svg viewBox="0 0 100 100" className={className}>   
    <g>
      <g>
        <path d="M87.3,59.8C87.3,59.8,87.3,59.8,87.3,59.8c0-2.2-1.3-4-3.2-4.8c-0.6-0.3-1.3-0.4-2-0.4h-3.6v-9.9c0,0,0,0,0,0
          c0-1.9-1-3.6-2.5-4.6c-0.9-0.6-2-1-3.1-1H61.6v-6.8c0-0.4-0.4-0.8-0.8-0.8H13.5c-0.4,0-0.8,0.4-0.8,0.8v38.5v4.2
          c0,0.4,0.4,0.8,0.8,0.8h5.6c0.4,3.1,3,5.5,6.2,5.5c3.2,0,5.8-2.4,6.2-5.5H63c0.4,3.1,3,5.5,6.2,5.5s5.8-2.4,6.2-5.5h11.1
          c0.4,0,0.8-0.4,0.8-0.8v-4.2c0,0,0,0,0,0V59.8z M72.9,70c-1-0.8-2.3-1.2-3.7-1.2c-1.4,0-2.7,0.5-3.7,1.2h-3.9V56.2h15.2V70H72.9z
          M63,74.3H31.5c-0.1-1-0.5-1.8-1-2.6h30.2h3.2C63.4,72.4,63.1,73.3,63,74.3z M21.6,70h-7.3V33.1H60v6.8c0,0,0,0,0,0c0,0,0,0,0,0
          v15.4c0,0,0,0,0,0s0,0,0,0V70H29c-1-0.8-2.3-1.2-3.7-1.2C23.9,68.8,22.6,69.2,21.6,70z M82,56.2C82,56.2,82,56.2,82,56.2
          c0.5,0,1,0.1,1.4,0.3c1.3,0.6,2.2,1.8,2.2,3.3V70h-7.2V56.2H82z M72.9,40.7C72.9,40.7,72.9,40.7,72.9,40.7c0.8,0,1.6,0.2,2.2,0.7
          c1,0.7,1.7,1.9,1.7,3.3v9.9H61.6V40.7L72.9,40.7L72.9,40.7z M14.3,71.6h5.7c-0.5,0.8-0.8,1.7-1,2.6h-4.8L14.3,71.6L14.3,71.6z
          M25.3,79.7c-2.6,0-4.6-2.1-4.6-4.6c0-2.6,2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6C30,77.6,27.9,79.7,25.3,79.7z M69.2,79.7
          c-2.6,0-4.6-2.1-4.6-4.6c0-2.6,2.1-4.6,4.6-4.6c2.6,0,4.6,2.1,4.6,4.6c0,0,0,0,0,0c0,0,0,0,0,0C73.8,77.6,71.8,79.7,69.2,79.7z
          M75.4,74.3c-0.1-1-0.5-1.8-1-2.6h3.2c0,0,0,0,0,0h8.1v2.6H75.4z"/>
        <path fill="#fff" d="M69.2,81.5c-3.2,0-5.9-2.3-6.3-5.5H31.6c-0.5,3.1-3.2,5.5-6.3,5.5c-3.2,0-5.9-2.3-6.3-5.5h-5.5
          c-0.5,0-0.9-0.4-0.9-0.9V32.3c0-0.5,0.4-0.9,0.9-0.9h47.3c0.5,0,0.9,0.4,0.9,0.9V39h11.1c1.1,0,2.2,0.3,3.2,1
          c1.6,1.1,2.5,2.8,2.5,4.7l0,9.8H82c0.7,0,1.4,0.1,2.1,0.4c2,0.8,3.3,2.8,3.3,5l0,15.3c0,0.5-0.4,0.9-0.9,0.9h-11
          C75.1,79.1,72.4,81.5,69.2,81.5z M31.4,75.7h31.7l0,0.1c0.4,3.1,3,5.4,6.1,5.4s5.7-2.3,6.1-5.4l0-0.1h11.2c0.4,0,0.7-0.3,0.7-0.7
          l0-15.3c0-2.1-1.2-3.9-3.1-4.7c-0.6-0.3-1.3-0.4-2-0.4h-3.8v-10c0-1.8-0.9-3.5-2.4-4.5c-0.9-0.6-1.9-0.9-3-0.9H61.5v-6.9
          c0-0.4-0.3-0.7-0.7-0.7H13.5c-0.4,0-0.7,0.3-0.7,0.7v42.8c0,0.4,0.3,0.7,0.7,0.7h5.7l0,0.1c0.4,3.1,3,5.4,6.1,5.4
          c3.1,0,5.7-2.3,6.1-5.4L31.4,75.7z M69.2,79.8c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8
          C74,77.7,71.8,79.8,69.2,79.8z M69.2,70.5c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C73.7,72.6,71.7,70.5,69.2,70.5
          z M25.3,79.8c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8S27.9,79.8,25.3,79.8z M25.3,70.5
          c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5S27.8,70.5,25.3,70.5z M85.8,74.4H75.3l0-0.1c-0.1-0.9-0.4-1.8-0.9-2.6
          l-0.1-0.2h11.6V74.4z M75.5,74.1h10v-2.4H74.7C75.1,72.5,75.4,73.3,75.5,74.1z M63.1,74.4H31.4l0-0.1c-0.1-0.9-0.4-1.8-0.9-2.6
          l-0.1-0.2h33.8L64,71.7c-0.5,0.8-0.8,1.7-0.9,2.6L63.1,74.4z M31.6,74.1h31.2c0.1-0.8,0.4-1.6,0.8-2.4H30.8
          C31.2,72.5,31.5,73.3,31.6,74.1z M19.2,74.4h-5v-2.9h6.1l-0.1,0.2c-0.5,0.8-0.8,1.6-0.9,2.6L19.2,74.4z M14.5,74.1H19
          c0.1-0.8,0.4-1.6,0.8-2.4h-5.4L14.5,74.1z M60.1,70.1H29l0,0c-1.1-0.8-2.3-1.2-3.6-1.2c0,0,0,0,0,0c-1.3,0-2.6,0.4-3.6,1.2l0,0
          h-7.4V33h45.9V70.1z M29.1,69.9h30.8V33.2H14.5v36.6h7.1c1.1-0.8,2.4-1.2,3.8-1.2c0,0,0,0,0,0C26.7,68.7,28,69.1,29.1,69.9z
          M85.8,70.1h-7.5V56.1H82c0.5,0,1,0.1,1.5,0.3c1.4,0.6,2.3,1.9,2.3,3.5V70.1z M78.5,69.9h7V59.8c0-1.4-0.8-2.7-2.1-3.2
          c-0.4-0.2-0.9-0.3-1.4-0.3h-3.5V69.9z M76.9,70.1h-4l0,0c-1.1-0.8-2.3-1.2-3.6-1.2s-2.6,0.4-3.6,1.2l0,0h-4V56.1h15.5V70.1z
          M73,69.9h3.7V56.3h-15v13.6h3.7c1.1-0.8,2.4-1.2,3.8-1.2S71.9,69.1,73,69.9z M76.9,54.7H61.5V40.6l11.5,0c0.8,0,1.5,0.3,2.1,0.7
          c1.1,0.8,1.8,2,1.8,3.4V54.7z M61.7,54.4h15v-9.8c0-1.3-0.6-2.4-1.7-3.2c-0.6-0.4-1.4-0.6-2.1-0.6l-11.1,0V54.4z"/>
      </g>
      <g>
        <path d="M68.1,58.4h-3.7c-0.5,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h3.7c0.4,0,0.8-0.4,0.8-0.8C68.9,58.8,68.6,58.4,68.1,58.4z
          "/>
        <path fill="#fff" d="M68.1,60.2h-3.7c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h3.7c0.5,0,0.9,0.4,0.9,0.9S68.6,60.2,68.1,60.2z
          M64.5,58.6c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h3.7c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7H64.5z"/>
      </g>
      <g>
        <path d="M13.5,28.9h41.4c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8H13.5c-0.4,0-0.8,0.4-0.8,0.8C12.7,28.6,13.1,28.9,13.5,28.9
          z"/>
        <path fill="#fff" d="M54.9,29H13.5c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h41.4c0.5,0,0.9,0.4,0.9,0.9
          C55.8,28.6,55.4,29,54.9,29z M13.5,27.4c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h41.4c0.4,0,0.7-0.3,0.7-0.7
          c0-0.4-0.3-0.7-0.7-0.7H13.5z"/>
      </g>
      <g>
        <path d="M13.5,24.6h29.1c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8H13.5c-0.4,0-0.8,0.4-0.8,0.8C12.7,24.2,13.1,24.6,13.5,24.6
          z"/>
        <path fill="#fff" d="M42.6,24.7H13.5c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h29.1c0.5,0,0.9,0.4,0.9,0.9
          C43.5,24.3,43.1,24.7,42.6,24.7z M13.5,23.1c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h29.1c0.4,0,0.7-0.3,0.7-0.7
          c0-0.4-0.3-0.7-0.7-0.7H13.5z"/>
      </g>
      <g>
        <path d="M13.5,20.3h15.2c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8H13.5c-0.4,0-0.8,0.4-0.8,0.8C12.7,19.9,13.1,20.3,13.5,20.3
          z"/>
        <path fill="#fff" d="M28.8,20.4H13.5c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h15.2c0.5,0,0.9,0.4,0.9,0.9S29.3,20.4,28.8,20.4z
          M13.5,18.8c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h15.2c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H13.5z"/>
      </g>
    </g>
  </Svg>
);

const ReturnPolicy = ({ className }) => ( 
  <Svg viewBox="0 0 100 100" className={className}>   
    <g>
      <path d="M85.4,63.5c-2.5-2.5-5.8-3.9-9.4-3.9s-6.9,1.4-9.4,3.9s-3.9,5.8-3.9,9.4c0,3.5,1.4,6.9,3.9,9.4s5.8,3.9,9.4,3.9
        s6.9-1.4,9.4-3.9s3.9-5.8,3.9-9.4C89.3,69.4,87.9,66.1,85.4,63.5z M84.5,81.4c-2.3,2.3-5.3,3.5-8.5,3.5s-6.2-1.2-8.5-3.5
        S64,76.1,64,72.9s1.2-6.2,3.5-8.5c2.3-2.3,5.3-3.5,8.5-3.5s6.2,1.2,8.5,3.5c2.3,2.3,3.5,5.3,3.5,8.5S86.8,79.1,84.5,81.4z"/>
      <path d="M80.8,68.1c-0.3-0.3-0.7-0.3-0.9,0l-4,4L72,68.2c-0.3-0.3-0.7-0.3-0.9,0c-0.3,0.3-0.3,0.7,0,0.9L75,73l-3.9,3.9
        c-0.3,0.3-0.3,0.7,0,0.9c0.1,0.1,0.3,0.2,0.5,0.2s0.3-0.1,0.5-0.2l3.9-3.9l3.9,3.9c0.1,0.1,0.3,0.2,0.5,0.2s0.3-0.1,0.5-0.2
        c0.3-0.3,0.3-0.7,0-0.9l-4-3.9l3.9-3.9C81,68.8,81,68.4,80.8,68.1z"/>
      <path d="M59.2,78.7l-15,7.6V45.4l12.2-6.1v15.4c0,0.2,0.1,0.4,0.3,0.6c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1l6.7-3.6
        c0.2-0.1,0.3-0.3,0.3-0.6V35.3l11-5.5v24.9c0,0.4,0.3,0.6,0.6,0.6c0.4,0,0.6-0.3,0.6-0.6V28.8c0-0.1,0-0.1,0-0.2l0,0
        c0,0,0-0.1-0.1-0.1l0,0l-0.1-0.1l0,0l-0.1-0.1l0,0L44,12c-0.2-0.1-0.4-0.1-0.6,0l-18.2,9.1c-0.2,0-0.4,0.1-0.5,0.3L11,28.2l0,0
        c0,0-0.1,0-0.1,0.1l0,0l-0.1,0.1l0,0c0,0,0,0.1-0.1,0.1l0,0c0,0.1,0,0.1,0,0.2V71c0,0.2,0.1,0.5,0.4,0.6L43.4,88l0,0h0.1l0,0h0.1
        h0.1l0,0h0.1l0,0l16-8.1c0.3-0.2,0.4-0.6,0.3-0.9C60,78.7,59.7,78.6,59.2,78.7z M57.4,37.2L26.6,21.8l5-2.5l31,15.5l-5,2.5
        C57.5,37.3,57.5,37.2,57.4,37.2z M63.2,50.7l-5.4,2.9v-15l5.4-2.7V50.7z M43.7,13.3l31,15.5L64,34.1L33,18.6L43.7,13.3z M25.1,22.5
        l31,15.5l-12.6,6.3l-31-15.5L25.1,22.5z M11.8,29.9L43,45.4v40.9L11.8,70.6V29.9z"/>
    </g>
  </Svg>
);

const Text = styled.div`
  color: #222;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function DeliveryOptions() {
  return (
    <Container>
      <Box>
        <SecurePayment/>
        <Text>
          Secure online payment
        </Text>
      </Box>
      <Box>
        <FastDelivery/>
        <Text>
          Fast home delivery
        </Text>
      </Box>
      <Box>
        <ReturnPolicy/>
        <Text>
          30-day return policy
        </Text>
      </Box>
    </Container>
  )
}

export default DeliveryOptions;
